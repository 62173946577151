import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'

import servicesData from '../../content/services.json';
import peopleData from '../../content/people.json';


import "../scss/pages/index.scss"

class Home extends React.Component {
	constructor(props){
		super(props)
		console.log(servicesData);
		this.state = {
			services: servicesData.services,
			active_service: {title:'', description_html:''},
			people: peopleData.people,
		}
	}
	componentDidMount(){
		this.setState({active_service:this.state.services[0]});
		
			window.jQuery(document).ready(function($){
				window.setTimeout(() => {
				var configProfile = {
					"profile": {"screenName": 'ghw_barristers'},
					"domId": 'twitterProfile',
					"maxTweets": 4,
					"enableLinks": true, 
					"showUser": true,
					"showTime": true,
					"showImages": false,
					"lang": 'en'
				};
				window.twitterFetcher.fetch(configProfile);
				window.setTimeout(() => {
					//window.jQuery('#twitterProfile ul').append('<li class="twitterProf bg-secondary text-white"></li>');
				},500);
				}, 500);
			});
		
	}
  render() {
	const { location, data } = this.props

    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="Home" />
        <div className="home">
			<section className="hero">
				<div className="container py-5">
					<div className="row top-hero align-items-center">
						<div className="col-md-10">
							<h1 className="h1 pt-5 pl-3 pl-md-5">The value of experience. <br className="d-none d-md-block"/>The&nbsp;strength of teamwork.</h1>
							<Link to="/contact" className="btn btn-primary ml-3 ml-md-5 mt-3">Speak to a lawyer</Link>
						</div>
						<div className="col-md-2 text-right align-self-end">			
							<Link className="mr-3" to="/contact/"><img className="icon" src="/img/icons/white-whatsapp.png" alt=""/></Link>
							<a target="_blank" href="https://twitter.com/GHW_barristers"><img className="icon" src="/img/icons/white-twitter.png" alt=""/></a><br/>				
						</div>	
					</div>
					<div className="row py-md-5">
						<div className="col-md-12 text-right">
							<h1 className="h1 pb-5 py-md-5 pr-3 pr-md-5 mt-4 mt-md-0">Recognized as one of <br className="d-none d-md-block" />Canada’s leading law&nbsp;firms. </h1>							
						</div>
					</div>
				</div>
			</section>
			<section className="section-2 py-5">
				<div className="container">
					<div className="row my-5">
						<div className="col-10 offset-1 col-md-6 offset-md-3 bg-primary text-white py-5 px-4 px-md-5 my-5">
							<p>Greenspan Humphrey Weinstein LLP practices criminal and related regulatory and administrative litigation.</p>
							<p>Our lawyers provide strategic and thoughtful representation to individuals, professionals, and corporations.</p>
							<Link to="/about" className="btn-link text-white">Read more</Link>
						</div>
					</div>
				</div>
			</section>
			<section className="section-3 bg-black pb-5">
				<div className="container py-5">
					<div className="row">
						<div className="col-md-10 offset-md-1">					
							<div className="row s3-bg-1 mb-5">						
								<div className="col-lg-7 offset-lg-5">
									<div className="row align-items-center">
										<div className="col-12 close-bracket-before my-5">
											<div className="bg-primary text-white p-4">
											<p>Led by partners Brian Greenspan, David Humphrey, Seth Weinstein, and Jill Makepeace, our experienced team handles a broad range of criminal and regulatory matters. </p>
											<p>Our sound advice and experience has been relied upon in domestic, international, and trans-border criminal and regulatory investigations and proceedings. </p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row s3-bg-2 mt-5">
								<div className="col-lg-6">
									<div className="row align-items-center">
										<div className="col-12 open-bracket-after mt-5">
											<div className="bg-primary text-white p-4">
											<p>Our counsel has been sought, and we have worked in collaboration with, colleagues across Canada and the United States as well as with firms in Europe and Asia. We provide skilled and insightful advocacy that has earned us a reputation for success and discretion.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="section-4 py-md-5">
				<div className="container">
					<div className="row my-md-5">
						<div className="col-md-6 text-white py-5 px-5 my-5">
							<h2 className="h1">An experienced team. A reputation for success and discretion.</h2>
							<Link to="/team/" className="btn btn-outline-white btn-small mt-3">Let's meet</Link>
						</div>
					</div>
				</div>
			</section>
			<section className="who-we-are bg-primary text-white py-5">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<h3 className="h3">Who we are</h3>
							<div className="w-100 who-we-are-grid">
								{this.state.people.slice(0,4).map((person)=>(
									<Link to={"/profiles/"+person.slug} className="thumbbio">
										<img src={"/img/people/"+person.slug+".jpg"} alt=""/>
										<div className="who-content px-4">
											<p>{person.name}<br/>
											<i>{person.title}</i></p>
											<div className="hover-link"><span className="btn-link text-white">Read more</span></div>
										</div>
									</Link>
								))}								
								<div className="bg-dark-purple px-4 py-5 py-md-3 d-flex flex-column justify-content-between">									
									<h3>Find the right advocate for your legal&nbsp;situation. </h3>
									<div>
										<Link to="/team" className="btn btn-outline-white btn-xsmall mt-3">Meet the whole team</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-7">
							<h2 className="h1 mt-5">Our skilled lawyers excel across a range of services.</h2>
						</div>
					</div>
				</div>
			</section>
			<section className="services bg-black py-5">
				<div className="container">
					<div className="row">
						<div className="col-md-12 bg-white py-5">
							<div className="row">
								<div className="col-md-4 offset-md-1">
									<h4 className="text-primary">What we do</h4>
									{this.state.services.map((this_service)=>(
										<button onClick={() => {this.setState({active_service:this_service})}} className="btn btn-link d-flex">
											<span className="mr-3">{this_service.id === this.state.active_service.id ? '-' : '+'}</span>
											<div className="mt-1">{this_service.title}</div>
										</button>
									))}
								</div>
								<div className="col-md-1"></div>
								<div className="col-md-5">
									<div className="mt-4" dangerouslySetInnerHTML={{ __html: this.state.active_service.description_html }} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="bg-white twitter-section py-5">
				<div className="container">
					<div className="row">
						<div className="col-12 d-flex d-lg-block flex-column px-0">
							<div id="twitterProfile"></div>
							<div className="twitterProf bg-primary text-white">
								<img className="pt-3 pb-2" src="/img/twitter-white.png" alt=""/><br/>
								Greenspan<br/>
								Humphrey<br/>
								Weinstein LLP<br/>
								<br/>
								@GHW_barristers<br/>
								<a href="https://twitter.com/GHW_barristers" target="_blank" className="btn btn-outline-white mt-5">Follow us on Twitter</a>
							</div>
						</div>
						<div className="clearboth"></div>
					</div>
				</div>
			</section>
        </div>
      </Layout>
    )
  }
}

export default Home